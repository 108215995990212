import _ from 'lodash';
import {pdfResponseDto} from "@app/store/pdf/types";

/**
 * Validation method to check if the given value is undefined or not.
 * @description - Uses lodash's `isUndefined` method to check the value
 *
 * @param {any} data - Data can be of any type (string, number,...)
 *
 * @returns {boolean} - Returns true if value is undefined, else false
 */
export function isUndefined(data: unknown): boolean {
    return _.isUndefined(data);
}

/**
 * Validation method to check if the given value is null or not.
 * @description - Uses lodash's `isNull` method to check the value
 *
 * @param {any} data - Data can be of any type (string, number,...)
 *
 * @returns {boolean} - Returns true if value is null, else false
 */
export function isNull(data: unknown): boolean {
    return _.isNull(data);
}


export function isNetherUndefinedNorNull(data: unknown): boolean {
    return !isUndefined(data) && !isNull(data);
}

//TODO this can be refactored in a better way
export const statusProps = (pdfInfo: pdfResponseDto) => {

    let currentStatus = pdfInfo.status;
    let bulletClassName = "bg-orange-1";
    let capsuleClassName = "bg-orange-2";

    if (!currentStatus) {
        currentStatus = "document.status.new_document";
        bulletClassName = "bg-black-1";
        capsuleClassName = "bg-black-4";
        return {
            currentStatus,
            bulletClassName,
            capsuleClassName
        }
    }

    if (currentStatus?.includes("failed") || currentStatus?.includes("failure")) {
        bulletClassName = "bg-red-300";
        capsuleClassName = "bg-red-200";
        return {
            currentStatus,
            bulletClassName,
            capsuleClassName
        }
    }

    switch (currentStatus) {
        case "document.status.annotating":
            bulletClassName = "bg-orange-1";
            capsuleClassName = "bg-orange-2";
            break;
        case "document.status.annotated":
            bulletClassName = "bg-green-1";
            capsuleClassName = "bg-green-2";
            break;
        case "document.status.anonymizing":
            bulletClassName = "bg-orange-1";
            capsuleClassName = "bg-orange-2";
            break;
        case "document.status.anonymized":
            bulletClassName = "bg-brand-2";
            capsuleClassName = "bg-brand-3";
            break;
        case "document.status.saving":
            bulletClassName = "bg-yellow-1";
            capsuleClassName = "bg-yellow-2";
            break;
        case "document.status.saved":
            bulletClassName = "bg-green-1";
            capsuleClassName = "bg-green-2";
            break;
        case "document.status.creating_new_document":
            bulletClassName = "bg-yellow-1";
            capsuleClassName = "bg-yellow-2";
            break;
        case "document.status.created_new_document":
            bulletClassName = "bg-green-1";
            capsuleClassName = "bg-green-2";
            break;
        case "document.status.corrupt_document":
            bulletClassName = "bg-red-300";
            capsuleClassName = "bg-red-200";
            break;
        case "document.status.converting_to_pdf_success":
            bulletClassName = "bg-green-1";
            capsuleClassName = "bg-green-2";
            break;
        case "document.status.converting_to_pdf":
            bulletClassName = "bg-orange-1";
            capsuleClassName = "bg-orange-2";
            break;
        case "document.status.busy":
            bulletClassName = "bg-orange-1";
            capsuleClassName = "bg-orange-2";
            break;
        case "document.status.unsupported":
            bulletClassName = "bg-red-300";
            capsuleClassName = "bg-red-200";
            break;
        default:
            break;
    }


    return {
        currentStatus,
        bulletClassName,
        capsuleClassName
    }
};
